import * as React from 'react';
import { Typography, styled } from '@mui/material';

import BaseLayout from 'layouts/Base';
import Button from 'components/Button';
import Section from 'components/Section';

const Text = styled(Typography)(({ theme }) => ({
	margin: theme.spacing(5, 0, 3),
	textAlign: 'center',
	[theme.breakpoints.down('sm')]: {
		margin: theme.spacing(3, 0),
	},
}));

export default () => (
	<BaseLayout
		title="404 Page Not Found - Atlas Scaffolding"
		metaTitle="404 Page Not Found - Atlas Scaffolding"
		metaDescription="404 Page Not Found - Atlas Scaffolding"
		darkNav
	>
		<Section padding title="Page Not Found" noMarginTitle>
			<Text>
				Sorry! Despite our computers looking very hard, we could not find that page.
			</Text>
			<Button color="primary" to="/">
				Return To Homepage
			</Button>
		</Section>
	</BaseLayout>
);
